import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { Grid } from "@mui/material"

import useStyles from "./styles"
import DemographicSection from "../../components/DemographicSection"
import CaseNoteCard from "../../components/CaseNoteCard"
import UpcomingActivityCard from "../../components/UpcomingActivityCard"
import { useAuth } from "../../hooks/useAuth"
import { USER_ROLES } from "../../constants"
import { selectMustimuhwData } from "../../state/patient/selector"
import { selectJMKData } from "../../state/patient/selector"
import { getLegalName } from "../../util/helpers"
import useGetPatientDemographics from "../../hooks/services/useGetPatientDemographics"
import useGetJMKClientById from "../../hooks/services/useGetJMKClientById"
import { updateData } from "../../state/patient"
import LoadingIndicator from "../../components/LoadingIndicator"

const PatientProfile = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isAuthorized } = useAuth()
  const { mustimuhwPatient: patient } = useSelector(selectMustimuhwData)
  const { jmkClient } = useSelector(selectJMKData)
  const { data: patientDemographics } = useGetPatientDemographics(
    patient?.mustimuhwKey
  )

  const localState = useLocation();
  const {data: jmkData} = useGetJMKClientById({ id: localState?.state?.jmkId })


  const [caseNotesFullScreen, setCaseNotesFullScreen] = useState(false)
  const [upcomingActFullScreen, setUpcomingActFullScreen] = useState(false)

  useEffect(() => {
    if (
      patientDemographics &&
      !patient?.dataLoadingComplete &&
      (jmkClient || jmkData)
    ) {
      dispatch(
        updateData({
          mustimuhwData: { ...patientDemographics, dataLoadingComplete: true },
          jmkData: jmkClient || jmkData,
        })
      )
    }
  }, [patientDemographics, jmkClient, jmkData])

  const legalName = getLegalName(patientDemographics?.aliases)

  return !patientDemographics ? (
    <LoadingIndicator width={80} height={80} marginTop="48vh" />
  ) : (
    <Grid xs={12} sx={classes.body}>
      <Grid item xs={3} sx={classes.demographic}>
        <DemographicSection
          misPatient={patientDemographics}
          jmkPatient={jmkClient}
        />
      </Grid>
      <Grid xs={9} sx={classes.main}>
        <Grid item xs={12} sx={classes.headingText}>
          {patientDemographics?.firstName + " " + patientDemographics?.lastName}
        </Grid>
        <Grid item xs={12} sx={classes.subheadingText}>
          {legalName &&
            legalName.firstName + " " + legalName.lastName + " (Legal)"}
        </Grid>
        <Grid sx={classes.notes}>
          {isAuthorized(USER_ROLES.LEVEL_4) && !upcomingActFullScreen ? (
            <Grid item xs={caseNotesFullScreen ? 12 : 6} sx={classes.casenote}>
              <CaseNoteCard
                patientId={patientDemographics?.mustimuhwKey}
                isFullscreen={caseNotesFullScreen}
                setFullscreen={setCaseNotesFullScreen}
              />
            </Grid>
          ) : null}

          {isAuthorized(USER_ROLES.LEVEL_2) && !caseNotesFullScreen ? (
            <Grid item xs={upcomingActFullScreen ? 12 : 6} sx={classes.upcoming}>
              <UpcomingActivityCard
                patientId={patientDemographics?.mustimuhwKey}
                isFullscreen={upcomingActFullScreen}
                setFullscreen={setUpcomingActFullScreen}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientProfile
