import { css } from "@emotion/react"

const styles = () => {
  const box = css`
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-right: 35px;
    max-height: calc(100vh - 80px);
  `

  const heading = css`
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: SemiBold, sans-serif;
    font-size: 19px;
    padding: 4px;
    margin-bottom: 12px;
  `

  const databox = css`
    padding: 10px 0;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: inherit;
  `

  const title = css`
    padding: 4px;
    font-family: SemiBold, sans-serif;
    font-size: 16px;
    cursor: inherit;
  `

  const value = css`
    padding: 3px;
    font-family: Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    cursor: inherit;
  `
  const scrollContainer = css`
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `

  const duplicatesHeading = css`
    font-family: Bold, sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
  `

  const duplicatesValue = css`
    font-family: Regular, sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);

    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 10px;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.75);
      vertical-align: middle;
    }
  `

  const duplicatesSource = css`
    font-family: Bold, sans-serif;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.70);
    padding-left: 5px;
  `

  const duplicatesBox = css`
    padding: 10px 25px;
  `

  return {
    heading,
    databox,
    title,
    value,
    box,
    scrollContainer,
    duplicatesBox,
    duplicatesHeading,
    duplicatesValue,
    duplicatesSource
  }
}

export default styles
