import { css } from "@emotion/react";

const getStyles = () => {

  const container = css`
    width: 350px;
  `;

  return { 
    container
   }
}

export default getStyles;