import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
  `;

  const headerbar = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 9999;
  `;

  const body = css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
    width: 100%;
    height: 100%;
  `;

  const recent = css`
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 0px;
    position: fixed;
    top: 10;
    left: 0;
    right: 0;
  `;

  const details = css`
    padding-top: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
  `;

  const card = css`
    padding-top: 50px;
    display: flex;
    flex-direction: row;
  `;

  const list = css`
    padding-top: 20px;
    display: flex;
    flex-direction: row;
  `;

  return {
    gridContainer,
    recent,
    details,
    card,
    list,
    headerbar,
    body,
  };
};

export default styles;
