import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: right;
  `;

  const card = ({ isSelected }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
    outline: ${isSelected
      ? "2px solid rgb(4, 134, 232)"
      : "1px solid rgba(125, 125, 125, 0.79)"};
    ${isSelected ? 'box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);' : ''}
    margin: 10px 0px;
    padding-right: 10px;
    transition: box-shadow .3s;
  `;

  const databox = css`
    padding: 10px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  `;

  const title = css`
    padding: 6px 4px;
    font-family: sans-serif;
    fontstyle: normal;
    font-weight: 550;
    font-size: 12px;
    cursor: pointer;
    color: #4b4b4b;
  `;

  const value = css`
    padding: 4px 4px 10px 4px;
    font-family: sans-serif;
    fontstyle: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  `;

  const row = css`
    padding: 4px 0px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  `;

  const circleicon = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px 30px 25px 20px;
    height: 30px;
  `;

  const accordionsummary = css`
    height: 80px;
    padding: 6px 6px 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 10px;
  `;

  const accordiondetails = css`
    padding-left: 10px;
    display: flex;
    flex-direction: row;
  `;

  const detailwrapper = css`
    padding: 10px 10px 10px 0px;
    display: flex;
    flex-direction: column;
  `;

  return {
    gridContainer,
    accordiondetails,
    accordionsummary,
    detailwrapper,
    circleicon,
    row,
    card,
    databox,
    value,
    title,
  };
};

export default styles;
