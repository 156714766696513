import { css } from "@emotion/react";

const getStyles = () => {

  const container = css`
    max-width: 200px;
  `;

  const noAttachments = css`
    font-family: SemiBold, sans-serif;
    font-size: 16px;
    padding: 25px;
  `;

  return {
    container,
    noAttachments
  };
};

export default getStyles;