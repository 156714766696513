import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: right;
    margin-right: 0px;
  `;

  const card = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(125, 125, 125, 0.79);
    padding: 30px;
    margin: 10px 0px 10px 0px;
  `;

  const databox = css`
    padding: 10px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  `;

  const header = css`
    padding: 20px 0px;
    font-family: SemiBold, sans-serif;
    font-size: 18px;
  `;

  const tabs = css`
    padding: 20px 0px;
  `;

  const bottom = css`
    padding: 20px 0px;
    display: flex;
    justify-content: right;
  `;

  const title = css`
    padding: 6px 4px;
    font-family: sans-serif;
    fontstyle: normal;
    font-weight: 550;
    font-size: 12px;
    cursor: text;
    color: #4b4b4b;
  `;

  const value = css`
    padding: 4px 4px 10px 4px;
    font-family: sans-serif;
    fontstyle: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: text;
  `;

  const row = css`
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  `;

  return {
    gridContainer,
    row,
    card,
    databox,
    value,
    title,
    header,
    tabs,
    bottom,
  };
};

export default styles;
