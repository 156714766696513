import { useQuery } from "react-query";

import { demogrphicsApi } from "../../Api";

const useInitialPatientSearch = ({
  search,
}) => {
  const fetchPatients = async () => {
    const query = new URLSearchParams({});

    if (search) {
      query.append("search", search);
    }

    try {
      const patients = await demogrphicsApi.get(
        `demographics/api/v1/patients/all?${query.toString()}`
      );

      const data = await patients.json();

      return data?.patients;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(
    ["patientsSearch", search],
    fetchPatients,
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useInitialPatientSearch;
