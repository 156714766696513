import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"

import store from "./state/store"
import App from "./App"

import "./index.css"

// fonts
import "./fonts/Inter-Bold.ttf"
import "./fonts/Inter-Medium.ttf"
import "./fonts/Inter-Regular.ttf"
import "./fonts/Inter-SemiBold.ttf"

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
)
