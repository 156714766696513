import { css } from "@emotion/react";

const styles = () => {
  const circle = css`
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `;

  const checked = css`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #0bc44f;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `;

  const tik = css`
    color: #0bc44f;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
  `;

  return {
    circle,
    checked,
    tik,
  };
};

export default styles;
