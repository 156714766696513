import { useInfiniteQuery } from "react-query"

import { patientDetailsApi } from "../../Api"

const useGetCaseNotes = (mustimuhwKey) => {
  const getCaseNotesByPatientId = async (nextPage) => {
    const query = new URLSearchParams({})

    if (nextPage) {
      query.append("page", nextPage)
    }

    try {
      const caseNotes = await patientDetailsApi.get(
        `patient-details/api/v1/case-notes/${mustimuhwKey}?${query.toString()}`
      )

      const data = await caseNotes.json()
      return data;
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return useInfiniteQuery(
    ["getCaseNotesByPatientId", mustimuhwKey],
    ({ pageParam = 0 }) => getCaseNotesByPatientId(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.nextPage >= 0) {
          return lastPage.nextPage;
        }
        return undefined;
      },
    }
  )
}

export default useGetCaseNotes
