import { useQuery } from "react-query"

import { patientDetailsApi } from "../../Api"

const useGetPrograms = (mustimuhwKey) => {
  const fetchPrograms = async () => {
    try {
      const upcomingActivities = await patientDetailsApi.get(
        `patient-details/api/v1/upcoming-activities/${mustimuhwKey}/programs`
      )

      const data = await upcomingActivities.json()
      return data;
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return useQuery(["getPrograms", mustimuhwKey], fetchPrograms, {
    refetchOnWindowFocus: false,
  })
}

export default useGetPrograms
