import { createSelector } from "reselect"

export const selectPatient = (state) => state.patient

export const selectMustimuhwData = createSelector(
  [selectPatient],
  ({ mustimuhwData }) => {
    return { mustimuhwPatient: mustimuhwData }
  }
)

export const selectJMKData = createSelector(
  [selectPatient],
  ({ jmkData }) => {
    return { jmkClient: jmkData }
  }
)