import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
  `;

  const body = css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  `;

  const main = css`
    margin-top: 120px;
  `;

  const notes = css`
    display: flex;
    flex-direction: row;
  `;

  const headingText = css`
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: Bold, sans-serif;
    font-size: 28px;
  `;
  const subheadingText = css`
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: sans-serif;
    font-size: 18px;
    padding: 8px 0px;
  `;

  const casenote = css`
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-right: 30px;
  `;

  const upcoming = css`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-right: 30px;
  `;

  const demographic = css`
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: left;
  `;

  const headerbar = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 9999;
  `;

  return {
    gridContainer,
    demographic,
    main,
    notes,
    headingText,
    subheadingText,
    casenote,
    upcoming,
    headerbar,
    body,
  };
};

export default styles;
