import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectAuthenticated, selectRoles } from "../../state/auth/selectors"
import { useAuth } from "../../hooks/useAuth"
import { selectLastLocation } from "../../state/lastLocation/selector"
import { ROUTE_UNAUTHORIZED } from "../../constants"

const minRoles = [`${process.env.REACT_SLFNHA_MINIMUM_ROLE}`]

const AuthCallback = () => {
  const { handleAuthentication } = useAuth()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(selectAuthenticated)
  const userRoles = useSelector(selectRoles)
  const { lastLoc, lastLocState } = useSelector(selectLastLocation)

  useEffect(() => {
    handleAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const accessToken = localStorage.getItem("SLFNHA_USER_ACCESS_TOKEN")
    if (isAuthenticated && accessToken) {
      const hasAtleastOneRole = userRoles.some((role) => {
        return minRoles.includes(role)
      })

      navigate('/')
    }
  }, [isAuthenticated, userRoles, navigate, lastLoc, lastLocState])

  return null
}

export default AuthCallback
