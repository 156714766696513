export const ROUTE_SIGN_IN = '/signin';
export const ROUTE_AUTH_CALLBACK = '/auth/callback/';
export const ROUTE_UNAUTHORIZED = '/unauthorized';

// Roles
export const USER_ROLES = {
  LEVEL_1: ['data-entry-clerks'],
  LEVEL_2: ['hotel-staff'],
  LEVEL_3: ['patient-coordinators', 'nca-clerks', 'medical-clerks'],
  LEVEL_4: ['allied-health-providers', 'healthcare']
}