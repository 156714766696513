import React, { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export function useSearch() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const [searchTerms, setSearchTerms] = useState([]);

  const handleSearch = (searchTerm) => {
    const newSearchTerm = {
      mappingId: searchTerms.length + 1,
      searchTerm,
    };

    setSearchTerms((prevSearchTerms) => [...prevSearchTerms, newSearchTerm]);
  };

  return (
    <SearchContext.Provider value={{ searchTerms, handleSearch }}>
      {children}
    </SearchContext.Provider>
  );
}
