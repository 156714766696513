import SvgIcon from "@mui/material/SvgIcon";

const CustomExpandIcon = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 20 L22 8 L2 8 Z" fill="rgb(4, 134, 232)" />
    </SvgIcon>
  );
};

export default CustomExpandIcon;
