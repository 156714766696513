import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  `;

  const gridContainerFs = css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 700px;
  `;

  const card = css`
    width: 100%;
    height: 600px;
    max-height: 600px;
    border-radius: 8px;
    border: 1px solid rgba(125, 125, 125, 0.45);
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 20px;
  `;

  const cardFs = css`
    width: 100%;
    height: 700px;
    max-height: 700px;
    border-radius: 8px;
    border: 1px solid rgba(125, 125, 125, 0.45);
    padding: 30px;
    margin-top: 20px;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
  `;

  const header = css`
    font-family: Bold, sans-serif;
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #111111;
    padding-bottom: 12px;
  `;

  const content = css`
    display: flex;
    flex-direction: column;
    height: calc(100% - 35px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #c2c2c2; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #b0b0b0; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #959595; 
    }
  `;

  const row = css`
    padding-right: 20px;
  `;

  const level2 = css`
    padding: 0px;
    margin-left: 30px;
  `;

  const dataBox = css`
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(125, 125, 125, 0.5);
    border-radius: 6px;
    margin: 5px 0 5px 30px;
  `;

  const dataRow = css`
    padding: 0px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
  `;

  const dataItem = css`
    padding: 0px;
    margin-right: 0px !important;
    justify-content: left;
    display: flex;
    flex-direction: row;
  `;

  const title = css`
    padding: 6px 4px;
    font-family: sans-serif;
    fontstyle: normal;
    font-weight: 600;
    font-size: 12px;
    cursor: text;
    color: #111111;
  `;

  const value = css`
    padding: 6px 0px;
    font-family: sans-serif;
    fontstyle: normal;
    font-weight: 500;
    font-size: 12px;
    cursor: text;
  `;

  return {
    gridContainer,
    gridContainerFs,
    card,
    cardFs,
    header,
    content,
    row,
    level2,
    dataBox,
    dataRow,
    dataItem,
    title,
    value,
  };
};

export default styles;
