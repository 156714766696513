import React, { useEffect } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { ROUTE_AUTH_CALLBACK } from '../../constants';

const SignIn = () => {
  const { login } = useAuth();

  useEffect(() => {
    login({
      redirectUri: `${process.env.REACT_APP_BASE_URL}${ROUTE_AUTH_CALLBACK}`,
    });
  }, [login]);

  return <h1>Redirecting to SignIn...</h1>;
};

export default SignIn;