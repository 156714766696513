import { Enumify } from 'enumify';

class UserRoleId extends Enumify {
  static PRODUCT_ADMIN = new UserRoleId({
    get id() {
      return '2a631c0d-1fa2-46e0-8c1c-01d6175dacb8';
    },
    get displayValue() {
      return 'Product Admin';
    },
  });

  static PLATFORM_ENGINEER = new UserRoleId({
    get id() {
      return '1bfe2dd0-001c-4b4b-b6aa-9563638ad52b';
    },
    get displayValue() {
      return 'Platform Engineer';
    },
  });

  static DEVOPS_ENGINEER = new UserRoleId({
    get id() {
      return '77970b43-a838-4502-901b-5c1135c149e6';
    },
    get displayValue() {
      return 'DevOps Engineer';
    },
  });

  static DEVELOPER = new UserRoleId({
    get id() {
      return '21460874-4771-44f6-8151-450a66153cae';
    },
    get displayValue() {
      return 'Developer';
    },
  });

  static APP_INSIGHTS_USER = new UserRoleId({
    get id() {
      return 'c65d03bc-16fc-4a2c-913f-388da68b4b7c';
    },
    get displayValue() {
      return 'App Insighs User';
    },
  });

  static BILLING_USER = new UserRoleId({
    get id() {
      return '4d4103ba-a8bd-4c54-bfd9-823d6a48a078';
    },
    get displayValue() {
      return 'Billing User';
    },
  });

  //csp users

  static ORG_ADMIN = new UserRoleId({
    get id() {
      return '2a631c0d-1fa2-46e0-8c1c-01d6175dacb9';
    },
    get displayValue() {
      return 'Org Admin';
    },
  });

  static CSP_MANAGER = new UserRoleId({
    get id() {
      return '2a631c0d-1fa2-46e0-8c1c-01d6175dacb2';
    },
    get displayValue() {
      return 'CSP Manager';
    },
  });

  static CSP_USER = new UserRoleId({
    get id() {
      return '764d973e-0446-11ed-b939-0242ac120002';
    },
    get displayValue() {
      return 'CSP User';
    },
  });

  static _ = this.closeEnum();

  static getDisplayValueOf(value) {
    const index = this.enumValues.findIndex((x) => x.enumKey === value);
    if (index >= 0) {
      return this.enumValues[index].displayValue;
    }
    return undefined;
  }

  static getDisplayValueById(value) {
    const index = this.enumValues.findIndex((x) => x.id === value);
    if (index >= 0) {
      return this.enumValues[index].displayValue;
    }
    return undefined;
  }

  static getEnumValueOf(value) {
    const index = this.enumValues.findIndex((x) => x.id === value);
    if (index >= 0) {
      return this.enumValues[index].enumKey;
    }
    return undefined;
  }

  static isValidGroupId(id) {
    const index = this.enumValues.findIndex((x) => x.id === id);
    return index >= 0;
  }

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default UserRoleId;
