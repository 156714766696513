import AuthRouter from "./components/AuthRouter"
import { AuthProvider } from "./hooks/useAuth"
import { ROUTE_AUTH_CALLBACK } from "./constants"

const initConfig = {
  onLoad: "login-required",
  promiseType: "native",
  redirectUri: `${process.env.REACT_APP_BASE_URL}${ROUTE_AUTH_CALLBACK}`,
}

function App() {
  return (
    <AuthProvider initConfig={initConfig}>
      <AuthRouter />
    </AuthProvider>
  )
}

export default App
