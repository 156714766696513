import { useState, useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';

import { SearchProvider } from "../../pages/SearchContext"
import MainLayout from "../../pages/MainLayout"
import PatientSearchResults from "../../pages/PatientSearchResults"
import PatientMatching from "../../pages/PatientMatching"
import PatientProfile from '../../pages/PatientProfile';
import { lastLocFetchStart, lastLocFetchSuccess } from '../../state/lastLocation'
import {  updateData } from '../../state/patient';
import { selectMustimuhwData } from '../../state/patient/selector';


const AppInitializer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState()
  const { mustimuhwPatient } = useSelector(selectMustimuhwData)

  const setSelectedPatient = (patient) => {
    dispatch(updateData({
      mustimuhwData: { ...patient, dataLoadingComplete: false }
    }))
  }

  useEffect(() => {
    dispatch(lastLocFetchStart());
    dispatch(
      lastLocFetchSuccess({
        lastLoc: location.pathname,
        lastLocState: location.state,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <SearchProvider>
      <div className="App">
        <header className="App-header"></header>
          <Routes>
            <Route
              path="/"
              element={<MainLayout setSearchTerm={setSearchTerm} />}
            >
              <Route
                path="/search"
                element={
                  <PatientSearchResults
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setSelectedPatient={setSelectedPatient}
                  />
                }
              />
              <Route
                path="/matching"
                element={
                  <PatientMatching
                  selectedPatient={mustimuhwPatient}
                  />
                }
              />
              <Route
                path="/profile"
                element={
                  <PatientProfile
                  selectedPatient={mustimuhwPatient}
                  />
                }
              />
            </Route>
          </Routes>
      </div>
    </SearchProvider>
  )
}

export default AppInitializer
