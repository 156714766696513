import { useQuery } from "react-query";

import { patientDetailsApi } from "../../Api"

const useGetJMKClients = ({
  firstName,
  lastName,
}) => {
  const fetchClients = async () => {
    const query = new URLSearchParams({});

    if (firstName) {
      query.append("first-name", firstName);
    }

    if (lastName) {
      query.append("last-name", lastName);
    }

    try {
      const clients = await patientDetailsApi.get(
        `patient-details/api/v1/jmk/clients?${query.toString()}`
      );

      const data = await clients.json();

      return data?.clients;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(
    ["getJMKClients", firstName, lastName],
    fetchClients,
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetJMKClients;
