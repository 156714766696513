import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import useStyles from "./styles"
import PaginatedList from "../PaginatedList"
import { updateData } from "../../state/patient"
import useCreateDataMapping from "../../hooks/services/useCreateDataMapping"

const DataMatchingList = ({ mustimuhwPatient, jmkClients }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedClient, setSelectedClient] = useState(null)

  const { mutateAsync: dataMappingCreator } = useCreateDataMapping()

  const handleDataChange = (data) => {
    setSelectedClient(data)
  }

  return (
    <Grid xs={12} container sx={classes.gridContainer}>
      <Grid item xs={10} sx={classes.header}>
        <span>No matching record found. Please select once confirmed.</span>
      </Grid>
      <Grid item xs={12} sx={classes.tabs}>
        <Grid>
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "#696969",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "0px",
              fontWeight: "400",
              fontSize: "18px",
              width: "80px",
              marginRight: "10px ",
              borderBottom: "4px solid rgb(4, 134, 232)",
              "&:hover": {
                backgroundColor: "#ededed",
                boxShadow: "0px 0px 1px 1px rgb(20 46 110 / 10%)",
              },
            }}
          >
            JMK
          </Button>
        </Grid>
        <hr style={{ margin: "0" }}></hr>
      </Grid>
      <PaginatedList clients={jmkClients} onDataSelected={handleDataChange} />

      <Grid item xs={12} sx={classes.bottom}>
        <Grid>
          <Button
            sx={{
              backgroundColor: "#426cb0",
              color: "#ffffff",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "5px",
              fontWeight: "600",
              fontSize: "16px",
              width: "120px",
              marginRight: "25px ",
              "&:hover": {
                backgroundColor: "#528ed3",
              },
              "&:focus": {
                border: "none",
              },
            }}
            onClick={() => {
              navigate("/profile")
            }}
          >
            Continue
          </Button>
          <Button
            sx={{
              backgroundColor: "#426cb0",
              color: "#ffffff",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "5px",
              fontWeight: "600",
              fontSize: "16px",
              width: "120px",
              "&:hover": {
                backgroundColor: "#528ed3",
              },
              "&:focus": {
                border: "none",
              },
            }}
            disabled={!selectedClient}
            onClick={() => {
              dispatch(
                updateData({
                  mustimuhwData: mustimuhwPatient,
                  jmkData: selectedClient,
                })
              )
              if (mustimuhwPatient?.mustimuhwKey && selectedClient?.id) {
                dataMappingCreator({
                  misId: mustimuhwPatient?.mustimuhwKey,
                  jmkId: selectedClient?.id,
                }).catch((err) => {
                  console.error(err)
                })
              }
              navigate("/profile", { state: {jmkId:selectedClient?.id} })
            }}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DataMatchingList
