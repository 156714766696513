import React from "react";

import { Grid, List, ListItem, ListItemText } from "@mui/material";

import useStyles from "./styles";
import { useSearch } from "../../pages/SearchContext";

const RecentSearch = ({ onItemClick }) => {
  const classes = useStyles();
  const { searchTerms } = useSearch();
  const reversedData = searchTerms.slice().reverse();

  return (
    <Grid
      xs={12}
      container
      sx={{ container: classes.gridContainer }}
      justifyContent="center"
    >
      <Grid xs={12} sx={classes.box}>
        {reversedData?.length > 0 && (
          <Grid item sx={classes.title}>
            <span>Recently Searched</span>
          </Grid>
        )}

        <Grid item sx={classes.listwrapper}>
          <List>
            {reversedData.map((item) => (
              <ListItem
                key={item.mappingId}
                // button
                onClick={() => onItemClick(item?.searchTerm)}
                sx={classes.listitem}
              >
                <ListItemText primary={item?.searchTerm} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecentSearch;
