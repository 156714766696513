import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    flex-direction: column;
    flex-wrap: nowrap;
  `;

  const title = css`
    padding: 15px 0px;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 18px;
  `;

  const sections = css`
    padding-top: 10px;
  `;

  const navigation = css`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `;

  return {
    gridContainer,
    sections,
    navigation,
    title,
  };
};

export default styles;
