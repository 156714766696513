import React from "react"

import { Grid } from "@mui/material"
import { DateTime } from "luxon"

import LoadingIndicator from '../LoadingIndicator'
import useStyles from "./styles"

import { useAuth } from "../../hooks/useAuth"
import { USER_ROLES } from "../../constants"

const DataItem = ({ title, value }) => {
  const classes = useStyles()

  return (
    <Grid xs={3} sx={classes.databox}>
      <Grid sx={classes.title}>{title}</Grid>
      <Grid sx={classes.value}>{value}</Grid>
    </Grid>
  )
}

const MustimuhwDataCard = ({ data }) => {
  const classes = useStyles()
  const { isAuthorized } = useAuth()

  const getLastUpdatedAddress = (addresses) => {
    if (!addresses) return null

    let date = DateTime.fromISO("1970-01-01T00:00:00")
    let index = -1
    addresses?.forEach((address, idx) => {
      if (address.updatedDate && date < DateTime.fromISO(address.updatedDate)) {
        date = DateTime.fromISO(address.lastUpdated)
        index = idx
      }
    })

    if (index < 0) {
      return addresses[0]
    } else return addresses[index]
  }

  const address = getLastUpdatedAddress(data?.addresses)

  return (
    <Grid xs={12} container sx={classes.gridContainer}>
      {!data && (
        <LoadingIndicator width={60} height={60} />
      )}

      {data && (
        <>
          <Grid item xs={10} sx={classes.header}>
            <span>
              {data?.firstName} {data?.lastName} (Mustimuhw)
            </span>
          </Grid>
          <Grid xs={12} sx={classes.card}>
            <Grid item xs={12} spacing={1} sx={classes.row}>
              <DataItem
                title="Name"
                value={data?.firstName + " " + data?.lastName}
              />
              <DataItem title="Date of Birth" value={data?.dob} />
              <DataItem title="Health Card" value={data?.healthCard} />
              <DataItem title="Status Number" value={data?.statusNumber} />
            </Grid>
            <Grid item xs={12} sx={classes.row}>
            {isAuthorized(USER_ROLES.LEVEL_2) ? (
              <DataItem
                title="Address"
                value={
                  <>
                    {address?.apartmentSuite
                      ? address?.apartmentSuite + "-" + address?.line1
                      : address?.line1}
                    <br />
                    {address?.city && address?.province
                      ? address?.city + " " + address?.province
                      : null}
                    <br />
                    {address?.postalCode}
                  </>
                }
              />
            ) : null}
              <DataItem title="Phone" value={data?.phonePrimary} />
              <DataItem title="Email" value={data?.email} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default MustimuhwDataCard
