import React, { useState, useEffect } from "react"

import { Grid, Box } from "@mui/material"
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import { IconButton, ListItem, ListItemText, Collapse } from "@mui/material"
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material"

import LoadingIndicator from "../LoadingIndicator"
import useStyles from "./styles"
import useGetPrograms from "../../hooks/services/useGetPrograms"
import useGetUpcomingActivities from "../../hooks/services/useGetUpcomingActivities"
import { getDateFormatted, removeWhitespaces } from "../../util/helpers"

const DataItem = ({ title, value }) => {
  const classes = useStyles()

  return (
    <Grid xs={6} sx={classes.dataItem}>
      <Grid xs={5} sx={classes.title}>
        {title}
      </Grid>
      <Grid sx={classes.value}>{value}</Grid>
    </Grid>
  )
}

const UpcomingActivityCard = ({ patientId, isFullscreen, setFullscreen }) => {
  const classes = useStyles()
  const [isExpanded, setExpanded] = useState(false)
  const [expandedPrograms, setExpandedPrograms] = useState([])
  const [expandedPurposes, setExpandedPurposes] = useState([])
  const [program, setProgram] = useState(null)
  const [purpose, setPurpose] = useState(null)
  const [upcomingActivities, setUpcomingActivities] = useState({})
  const [nextRenderLoopToggle, setNextRenderLoopToggle] = useState(false) // This is to stop infinite useEffect calling

  const { data: allPrograms } = useGetPrograms(patientId)
  const { data: activityData } = useGetUpcomingActivities(
    patientId,
    program,
    purpose
  )

  const flattenedActivityData = activityData?.pages.reduce((accum, curr) => {
    return [...accum, ...curr.upcomingActivities]
  }, [])

  const getActivityKey = (program, purpose) => {
    return removeWhitespaces(`${program}_${purpose}`)
  }

  const toggleExpandCard = () => {
    setExpanded(!isExpanded)
    setFullscreen(!isFullscreen)
  }

  const toggleExpandProgram = (itemIndex, program) => {
    if (expandedPrograms.includes(itemIndex)) {
      setExpandedPrograms(
        expandedPrograms.filter((index) => index !== itemIndex)
      )
    } else {
      setExpandedPrograms([...expandedPrograms, itemIndex])
      setProgram(program)
    }
  }

  const toggleExpandPurpose = (itemIndex, purpose) => {
    if (expandedPurposes.includes(itemIndex)) {
      setExpandedPurposes(
        expandedPurposes.filter((index) => index !== itemIndex)
      )
    } else {
      setExpandedPurposes([...expandedPurposes, itemIndex])
      setPurpose(purpose)
    }
  }

  useEffect(() => {
    if (!flattenedActivityData) {
      setNextRenderLoopToggle(!nextRenderLoopToggle)
    }
    if (program && purpose && flattenedActivityData) {
      const newActivites = {
        ...upcomingActivities,
        [getActivityKey(program, purpose)]: [...flattenedActivityData],
      }
      setUpcomingActivities(newActivites)
    }
  }, [program, purpose, nextRenderLoopToggle])

  return (
    <Grid xs={12} sx={isFullscreen ? classes.gridContainerFs : classes.gridContainer}>
      <Grid xs={12} sx={isFullscreen ? classes.cardFs : classes.card}>
        <Grid item xs={12} sx={classes.header}>
          <Box xs={11}>
            <span>Upcoming Activities</span>
          </Box>
          <Box
            xs={1}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
            onClick={toggleExpandCard}
          >
            <Grid>
              <ArrowOutwardIcon
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right !important",
                  width: "15px",
                  height: "15px",
                  marginLeft: "13px",
                  "& svg": {
                    strokeWidth: 5,
                  },
                }}
              />
            </Grid>
            <Grid>
              <ArrowOutwardIcon
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  width: "15px",
                  height: "15px",
                  transform: "rotate(180deg)",
                  "& svg": {
                    strokeWidth: 5,
                  },
                }}
              />
            </Grid>
          </Box>
        </Grid>
        <Box id="contentBox" sx={classes.content}>
          <Grid container>
            {allPrograms &&
              allPrograms.map((program, index) => (
                <Grid item xs={12} sx={classes.row} key={index}>
                  <ListItem
                    button
                    sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                    onClick={() => toggleExpandProgram(index, program?.program)}
                  >
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      edge="start"
                      onClick={() =>
                        toggleExpandProgram(index, program?.program)
                      }
                    >
                      {expandedPrograms.includes(index) ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </IconButton>
                    <ListItemText primary={program?.program} />
                  </ListItem>
                  <Collapse in={expandedPrograms.includes(index)}>
                    <Grid sx={classes.level2}>
                      {program?.purposes?.map((purpose, subIndex) => (
                        <Grid key={subIndex}>
                          <ListItem
                            sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                            button
                            onClick={() =>
                              toggleExpandPurpose(
                                `L2${index}-${subIndex}`,
                                purpose?.purpose
                              )
                            }
                          >
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              edge="start"
                              onClick={() =>
                                toggleExpandPurpose(
                                  `L2${index}-${subIndex}`,
                                  purpose?.purpose
                                )
                              }
                            >
                              {expandedPurposes.includes(
                                `L2${index}-${subIndex}`
                              ) ? (
                                <RemoveIcon />
                              ) : (
                                <AddIcon />
                              )}
                            </IconButton>
                            <ListItemText primary={purpose?.purpose} />
                          </ListItem>
                          <Collapse
                            in={expandedPurposes.includes(
                              `L2${index}-${subIndex}`
                            )}
                          >
                            {!upcomingActivities[
                              getActivityKey(program?.program, purpose?.purpose)
                            ] && <LoadingIndicator width={40} height={40} />}
                            {upcomingActivities[
                              getActivityKey(program?.program, purpose?.purpose)
                            ] &&
                              upcomingActivities[
                                getActivityKey(
                                  program?.program,
                                  purpose?.purpose
                                )
                              ].map((activity, index) => (
                                <Grid sx={classes.dataBox} key={index}>
                                  <Grid sx={classes.dataRow}>
                                    <DataItem
                                      title="Date"
                                      value={getDateFormatted(
                                        activity?.appointmentDate
                                      )}
                                    />
                                  </Grid>
                                  <Grid sx={classes.dataRow}>
                                    <DataItem
                                      title="Start Time"
                                      value={activity?.time}
                                    />
                                    <DataItem
                                      title="Duration"
                                      value={activity?.duration}
                                    />
                                  </Grid>
                                  <Grid sx={classes.dataRow}>
                                    <DataItem
                                      title="Location"
                                      value={activity?.location}
                                    />
                                  </Grid>
                                  <Grid sx={classes.dataRow}>
                                    <DataItem
                                      title="Staff"
                                      value={activity?.staff}
                                    />
                                    <DataItem
                                      title="Visit"
                                      value={activity?.typeOfVisit}
                                    />
                                  </Grid>
                                  {/* <Grid sx={classes.dataRow}>
                                  <DataItem
                                    title="Booked By"
                                    value={purpose.appointment.bookedBy}
                                  />
                                  <DataItem
                                    title="Booked On"
                                    value={purpose.appointment.bookedOn}
                                  />
                                </Grid> */}
                                </Grid>
                              ))}
                          </Collapse>
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UpcomingActivityCard
