import React, { useState } from "react"

import { Grid, Box, Typography } from "@mui/material"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Popover from "@mui/material/Popover"

import useStyles from "./styles"
import { useAuth } from "../../hooks/useAuth"
import { USER_ROLES } from "../../constants"
import {
  getAge,
  getDateFormatted,
  getPhoneNumbers,
  getGender,
  getLastUpdatedDataIndex,
  getAddresses
} from "../../util/helpers"

const DataItem = ({ title, value, duplicates = [] }) => {
  const classes = useStyles()

  const [anchor, setAnchor] = useState(null)

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const open = Boolean(anchor)
  const id = open ? "simple-popper" : undefined

  return (
    <Grid xs={12} spacing={1} sx={classes.databox}>
      <Grid sx={classes.title}>{title}</Grid>
      <Grid sx={classes.value}>
        {value}
        {duplicates.length > 0 && (
          <Box
            component="span"
            sx={{
              display: "inline-block",
              width: "8px",
              height: "8px",
              backgroundColor: "rgb(246 104 112)",
              borderRadius: "50%",
              marginLeft: "3px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={classes.duplicatesBox}>
            <Grid container direction="column">
              <Grid item>
                <Typography sx={classes.duplicatesHeading}>
                  We found {duplicates?.length} records
                </Typography>
              </Grid>
              <List dense={true}>
                {duplicates.map((item) => (
                  <ListItem>
                    <ListItemText
                      primary={
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "safe center",
                          }}
                        >
                          <Typography sx={classes.duplicatesValue}>
                            {item.value}
                          </Typography>
                          <Typography sx={classes.duplicatesSource}>
                            {"(" + item.source + ")"}
                          </Typography>
                        </span>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Box>
        </Popover>
      </Grid>
    </Grid>
  )
}

const DemographicSection = ({ misPatient, jmkPatient }) => {
  const classes = useStyles()
  const { isAuthorized } = useAuth()

  const phoneNumbers = getPhoneNumbers(misPatient, jmkPatient)
  const latestMisAddrIdx = getLastUpdatedDataIndex(misPatient?.addresses)
  const address = misPatient?.addresses[latestMisAddrIdx]
  const formatedAddresses= getAddresses(misPatient, jmkPatient)
  return (
    <Grid xs={12} sx={classes.box}>
      <Grid item sx={classes.heading}>
        <span>About {misPatient?.firstName + " " + misPatient?.lastName}</span>
      </Grid>
      <Box sx={classes.scrollContainer}>
        {isAuthorized(USER_ROLES.LEVEL_2) ? (
          <DataItem
            title="Address"
            value={
              address?.line1 ? (
                <>
                  {address?.apartmentSuite
                    ? address?.apartmentSuite + "-" + address?.line1
                    : address?.line1}
                  <br />
                  {address?.city && address?.province
                    ? address?.city + " " + address?.province
                    : null}
                  <br />
                  {address?.postalCode}
                </>
              ) : (
                "N/A"
              )
            }
            duplicates={
              formatedAddresses?.length > 1 ? formatedAddresses : []
            }
          />
        ) : null}
        {isAuthorized(USER_ROLES.LEVEL_2) ? (
          <DataItem title="Community" value={misPatient?.communityName} />
        ) : null}
        {isAuthorized(USER_ROLES.LEVEL_2) ? (
          <DataItem
            title="Phone"
            value={phoneNumbers.length > 0 ? phoneNumbers[0]?.value : "N/A"}
            duplicates={phoneNumbers}
          />
        ) : null}
        {isAuthorized(USER_ROLES.LEVEL_2) ? (
          <DataItem title="Email" value={misPatient?.email || "N/A"} />
        ) : null}
        {isAuthorized(USER_ROLES.LEVEL_2) ? (
          <DataItem
            title="Age"
            value={misPatient?.dob ? getAge(misPatient?.dob) : "N/A"}
          />
        ) : null}

        <DataItem
          title="Born"
          value={misPatient?.dob ? getDateFormatted(misPatient?.dob) : "N/A"}
        />
        {isAuthorized(USER_ROLES.LEVEL_3) ? (
          <DataItem
            title="Gender"
            value={misPatient?.gender ? getGender(misPatient?.gender) : "N/A"}
          />
        ) : null}
        {/* Physician is not available in the BE */}
        {/* {isAuthorized(USER_ROLES.LEVEL_3) ? (
          <DataItem title="Physician" value={data.physician} />
        ) : null} */}
        <DataItem
          title="Health Card"
          value={misPatient?.healthCardNumber || "N/A"}
        />
        <DataItem
          title="Status Number"
          value={misPatient?.statusNumber || "N/A"}
        />
        {isAuthorized(USER_ROLES.LEVEL_2) ? (
          <DataItem title="Wheelchair" value={jmkPatient?.wheelchair || "N/A"} />
        ) : null}

        {isAuthorized(USER_ROLES.LEVEL_3) ? (
          <DataItem title="Allergies" value={misPatient?.allergies || "N/A"} />
        ) : null}
      </Box>
    </Grid>
  )
}

export default DemographicSection
