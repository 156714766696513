import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
  `;

  const body = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  width: 100%;
`;

  const searchresults = css`
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    margin-right: 0px;
    padding-right: 100px;
  `;

  const recent = css`
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center; 
  margin-right: 0px;
  position: fixed;
  top: 10;
  left: 0;
  right: 0;
`;

  const headerbar = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width:100%;
    display: flex;
    flex-direction: row;
    z-index:9999;
  `;

  return {
    gridContainer,
    recent,
    searchresults,
    headerbar,
    body,
  };
};

export default styles;
