import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  `;

  const card = css`
    width: 100%;
    height: 600px;
    max-height: 600px;
    border-radius: 8px;
    border: 1px solid rgba(125, 125, 125, 0.45);
    padding: 30px;
    margin-top: 20px;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
  `;

  const cardFs = css`
    width: 100%;
    height: 700px;
    max-height: 700px;
    border-radius: 8px;
    border: 1px solid rgba(125, 125, 125, 0.45);
    padding: 30px;
    margin-top: 20px;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
  `;

  const header = css`
    font-family: Bold, sans-serif;
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #111111;
    padding-bottom: 15px;
  `;

  const content = css`
    display: flex;
    flex-direction: column;
    height: calc(100% - 35px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #c2c2c2; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #b0b0b0; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #959595; 
    }
  `;

  const contentFS = css`
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
    height: calc(100% - 55px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #c2c2c2; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #b0b0b0; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #959595; 
    }
  `;

  const set = css`
    padding: 5px 20px 50px 0px;
  `;

  const row = css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  `;

  const line1 = css`
    padding: 0px 0px 6px 0px;
    font-family: SemiBold, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.75);
  `;

  const line2 = css`
    padding: 0px 0px 8px 0px;
    font-family: SemiBold, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.75);
  `;

  const line3 = css`
    padding: 0px 0px 8px 0px;
    font-family: Regular, sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  `;

  const synopsis = css`
    padding: 0px 0px 6px 6px;
    font-family: Regular, sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  `;

  const syno = css`
    display: flex;
    flex-direction: row;
    align-items: start;
  `;

  const para = css`
    font-family: Regular, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
  `;

  return {
    gridContainer,
    card,
    cardFs,
    header,
    content,
    contentFS,
    set,
    row,
    line1,
    line2,
    line3,
    synopsis,
    syno,
    para,
  };
};

export default styles;
