import { createSlice } from '@reduxjs/toolkit';
import UserRoleId from '../../schema/user-role-id';

export const INITIAL_STATE = {
  isAuthenticating: false,
  isAuthenticated: false,
  profile: {},
  roles: [],
  groupId: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    authStart(state, action) {
      state.isAuthenticating = true;
    },
    authSuccess(state, { payload: { authenticated, profile, roles } }) {
      state.isAuthenticating = false;
      state.isAuthenticated = authenticated || state.isAuthenticated;
      state.profile = profile || state.profile;
      state.roles = roles || state.roles;
      state.groupId = profile?.attributes?.groupId?.find((id) =>
        UserRoleId.isValidGroupId(id)
      );
    },
    authError(state, action) {
      state.isAuthenticating = false;
    },
    updateProfile(state, { payload: { firstName, lastName } }) {
      state.profile.firstName = firstName || state.profile.firstName;
      state.profile.lastName = lastName || state.profile.lastName;
    },
  },
});

export const { name, actions, reducer } = authSlice;
export const { authStart, authSuccess, authError, updateProfile } = actions;
export default reducer;