import { useMutation, useQueryClient } from 'react-query';

import { patientDetailsApi } from "../../Api"

const useCreateDataMapping = () => {
  const QueryClient = useQueryClient();
  const url = `patient-details/api/v1/data-mappings`;

  return useMutation(
    async (obj) =>
      await patientDetailsApi
        .post(url, JSON.stringify(obj))
        .then((data) => data.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useCreateDataMapping;