import { useQuery } from "react-query"

import { patientDetailsApi } from "../../Api"

const useGetDataMappings = ({ misId }) => {
  const fetchDataMappings = async () => {
    let url = "patient-details/api/v1/data-mappings"

    if (misId) {
      url += `/${misId}`
    }

    try {
      const dataMappings = await patientDetailsApi.get(url)

      const data = await dataMappings.json()

      return misId ? data : data?.mappings
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return useQuery(["getDataMappings", misId], fetchDataMappings, {
    refetchOnWindowFocus: false,
  })
}

export default useGetDataMappings
