import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    padding: 8px;
    align-items: center;
    display: flex;
    justify-content: right;
    flex-direction: row;
    background-color: #528ed3 !important;   
  `;

  const searchbar = css`
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row;
    width: 100%;
    margin: 0px;
  `;

  const profile = css`
    display: flex;
    flex-direction: row;
    width: 100%;
  `;

  return {
    gridContainer,
    searchbar,
    profile,
  };
};

export default styles;
