import React from "react"
import { Grid } from "@mui/material"
import { Outlet } from "react-router-dom"

import useStyles from "./styles"
import HeaderBar from "../../components/HeaderBar"

const MainLayout = ({ setSearchTerm }) => {
  const classes = useStyles()

  return (
      <Grid xs={12} container sx={classes.gridContainer}>
        <Grid item xs={12} sx={classes.headerbar}>
          <HeaderBar setSearchTerm={setSearchTerm} />
        </Grid>
        <Outlet />
      </Grid>
  )
}

export default MainLayout
