import React, { useState } from "react";

import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CustomCircleIcon from "../CustomCircleIcon";
import CustomExpandIcon from "../CustomExpandIcon";

import useStyles from "./styles";

import { useAuth } from "../../hooks/useAuth"
import { USER_ROLES } from "../../constants"

const DataItem = ({ title, value }) => {
  const clas = useStyles();

  return (
    <Grid xs={3} sx={clas.databox}>
      <Grid sx={clas.title}>{title}</Grid>
      <Grid sx={clas.value}>{value}</Grid>
    </Grid>
  );
};

const CollapsibleCard = ({ data, index, isSelected, setSelectedIndex }) => {
  const classes = useStyles();
  const { isAuthorized } = useAuth();

  const handleSelect = () => {
    setSelectedIndex(index);
  };

  return (
    <Grid xs={12} container sx={classes.gridContainer}>
      <Grid xs={12} sx={classes.card({ isSelected })}>
        <Grid item sx={classes.circleicon} onClick={handleSelect}>
          <CustomCircleIcon isChecked={isSelected} />
        </Grid>
        <Grid item xs={11}>
          <Accordion sx={{ boxShadow: "none" }}>
            <AccordionSummary
              sx={classes.accordionsummary}
              expandIcon={<CustomExpandIcon />}
            >
              <Grid item xs={12} sx={classes.row}>
                <DataItem title="Name" value={data?.name} />
                <DataItem title="Date of Birth" value={data?.dateOfBirth} />
                <DataItem title="Status Number" value={data?.statusNumber} />
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={classes.accordiondetails}>
              <Grid xs={12} sx={classes.detailwrapper}>
                <Grid item xs={12} sx={classes.row}>
                  {isAuthorized(USER_ROLES.LEVEL_2) ? (
                    <DataItem
                      title="Address"
                      value={data?.address}
                    />
                  ) : null}
                  <DataItem title="Phone" value={data?.phone} />
                </Grid>
                <Grid item xs={12} sx={classes.row}>
                  <DataItem title="Allergies" value={data?.allergies} />
                  <DataItem title="Wheelchair" value={data?.wheelchair} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CollapsibleCard;
