import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import SignIn from '../../services/SignIn'
import AuthCallback from '../AuthCallback'
import AppInitializer from "../AppInitializer"
import { ROUTE_AUTH_CALLBACK, ROUTE_SIGN_IN } from "../../constants"

const AuthRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={ROUTE_AUTH_CALLBACK} element={<AuthCallback />} />
        <Route exact path={ROUTE_SIGN_IN} element={<SignIn />} />
        <Route path="*" element={<AppInitializer />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AuthRouter
