import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
    align-items: center;
    margin: 0px !important;
  `;

  const card = css`
    display: flex;
    justify-content: space-between;
    min-width: 80%;
    border-radius: 8px;
    border: 1px solid #9B9B9B;
    background: #FFF;
    padding: 0 75px;
    margin-bottom: 14px;
    transition: box-shadow .3s;
    cursor: pointer;

    &:hover {
      box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
    }
  `;

  const databox = css`
    padding: 15px 0;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: inherit;
  `;

  const title = css`
    padding: 6px 4px;
    font-family: Bold, sans-serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.75);
    cursor: inherit;
  `;

  const value = css`
    padding: 4px;
    font-family: Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    cursor: inherit;
  `;

  return {
    gridContainer,
    card,
    databox,
    value,
    title,
  };
};

export default styles;
