// noinspection JSUnresolvedVariable
const config = {
  baseUrl: `${process.env.REACT_APP_BASE_DEMOGRAPHIC_SERVICE_URL}`,
  mode: 'cors',
  headers: {},
  params: {},
  timeout: `${process.env.REACT_APP_REQUEST_TIMEOUT}`,
};

export default config;
