import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0px;
    justify-content:right; 
  `;

  const title = css`
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 18px;
  `;

  const sections = css`
    display: flex;
    flex-direction: column;
    align-item: flex-end;
    margin: 0px;
    padding-top: 70px;
  `;

  const searchItemList = css`
    margin-top: 38px;
  `;

  return {
    gridContainer,
    sections,
    title,
    searchItemList,
  };
};

export default styles;
