import React, { createContext, useEffect, useState, useCallback } from "react"
import Keycloak from "keycloak-js"

import LoadingIndicator from '../../components/LoadingIndicator'

const keycloak = new Keycloak(
  {
    url: `${process.env.REACT_APP_BASE_KEYCLOAK_URL}`,
    realm: "sioux-lookout",
    clientId: "sioux-lookout",
    KeycloakResponseType: "code"
  }
)

const defaultConfig = {
  onLoad: "check-sso",
  promiseType: "native",
  enableLogging: false,
  checkLoginIframe: false,
}

export const AuthContext = createContext({
  isInitialized: false,
  keycloak: null,
})

export const AuthProvider = ({ children, onEvent, initConfig }) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const onTokenExpired = useCallback(async () => {
    const tokenUpdated = await keycloak.updateToken(0)
    if (tokenUpdated) {
      localStorage.setItem("SLFNHA_USER_ACCESS_TOKEN", keycloak.token)
    } else {
      console.log("Error refreshing the token")
    }

    onEvent && onEvent("onTokenExpired")
  }, [onEvent])

  keycloak.onTokenExpired = onTokenExpired

  const onReady = useCallback(() => {
    setIsInitialized(true)
    localStorage.setItem("SLFNHA_USER_ACCESS_TOKEN", keycloak.token)

    onEvent && onEvent("onReady")
  }, [onEvent])

  keycloak.onReady = onReady

  useEffect(() => {
    keycloak.init({ ...defaultConfig, ...initConfig })

    return () => {
      keycloak.onReady = null
    }
  }, [initConfig])

  if (!isInitialized) {
    return (
      <LoadingIndicator width={80} height={80} />
    )
  }

  return (
    <AuthContext.Provider value={{ isInitialized, keycloak }}>
      {children}
    </AuthContext.Provider>
  )
}

export { keycloak }
