import { useState, useEffect } from "react"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography'
import { IconButton } from "@mui/material"
import { fileTypeFromBuffer } from "file-type"
import { Buffer } from "buffer"
import { saveAs } from 'file-saver'

import PdfIcon from "../../icons/PdfIcon"
import DownloadIcon from "../../icons/DownloadIcon"
import useGetAttachments from "../../hooks/services/useGetAttachments"
import LoadingIndicator from "../LoadingIndicator"
import getStyles from './styles'

const Attachments = ({ encounterId }) => {
  const styles = getStyles()
  const [attachments, setAttachments] = useState(null)

  const { data: attachmentsData } = useGetAttachments(encounterId)

  const getFileType = async (base64Str) => {
    const buffer = Buffer.from(base64Str, "base64")
    const type = await fileTypeFromBuffer(buffer)
    if (type) {
      return type.mime
    } else return "application/octet-stream"
  }

  const openFileInNewTab = (type, url) => {
    try {
      var w = window.open("")

      if (w) {
        w.document.write(
          `<embed width="100%" height="100%" src="${url}" type="${type}" />`
        )
      } else {
        throw new Error("Failed to open a new tab.")
      }
    } catch (error) {
      console.error("Error opening file in new tab:", error)
    }
  }

  const openOrDownload = (attachment) => {
    if (attachment.type.startsWith("image/") || attachment.type === "application/pdf") {
      openFileInNewTab(attachment.type, attachment.url)
    }
    else {
      let bstr = atob(attachment.data);
      let n = bstr.length;
      let uint8Array = new Uint8Array(n);
      while (n--) {
         uint8Array[n] = bstr.charCodeAt(n);
      }
      let file = new File([uint8Array], attachment.fileName, { type: attachment.type });
      saveAs(file, attachment.fileName);
    }
  }

  const getFileUrl = (type, data) => {
    return `data:${type};base64,${data}`
  }

  useEffect(() => {
    const generateFileUrls = async () => {
      if (attachmentsData) {
        await Promise.all(
          attachmentsData.map(async (attachment) => {
            const type = await getFileType(attachment.data)
            const url = getFileUrl(type, attachment.data)

            attachment.type = type
            attachment.url = url
          })
        )

        setAttachments([...attachmentsData])
      }
    }

    generateFileUrls().catch(console.error)
  }, [attachmentsData])

  return !attachments ? (
    <Box style={{ paddingTop: 15, paddingBottom: 15 }}>
      <LoadingIndicator width={35} height={35} />
    </Box>
  ) : attachments.length > 0 ? (
    <List dense={true}>
      {attachments.map((attachment) => (
        <ListItem
          secondaryAction={
            attachment.url && (
              <IconButton
                onClick={() =>
                  openOrDownload(attachment)
                }
              >
                <DownloadIcon />
              </IconButton>
            )
          }
        >
          <ListItemIcon>
            <PdfIcon />
          </ListItemIcon>
          <ListItemText primary={attachment.fileName} />
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography sx={styles.noAttachments}>No attachments found</Typography>
  )
}

export default Attachments
