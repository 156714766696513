import React from "react";
import { Grid } from "@mui/material";
import useStyles from "./styles";

const DataItem = ({ title, value }) => {
  const classes = useStyles();

  return (
    <Grid xs={2.5} spacing={1} sx={classes.databox}>
      <Grid sx={classes.title}>{title}</Grid>
      <Grid sx={classes.value}>{value}</Grid>
    </Grid>
  );
};

const SearchItem = ({ data, onSelected }) => {
  const classes = useStyles();

  const getFullName = (data) => {
    if (data?.middleName) {
      return `${data?.firstName} ${data?.middleName} ${data?.lastName}`
    }
    else return `${data.firstName} ${data.lastName}`
  }

  return (
    <Grid sx={classes.gridContainer} onClick={() => onSelected(data)}>
      <Grid xs={12} sx={classes.card}>
        <DataItem title="Name" value={getFullName(data)}/>
        <DataItem title="Date of Birth" value={data.dob} />
        <DataItem title="Health Card" value={data.healthCard} />
        <DataItem title="Status Number" value={data.statusNumber} />
      </Grid>
    </Grid>
  );
};

export default SearchItem;
