import { DateTime } from "luxon"

const getDateFormatted = (dob) => {
  const date = DateTime.fromISO(dob)
  return date.toFormat('LLL dd, yyyy')
}

const getAge = (dob) => {
  const date = DateTime.fromISO(dob)
  return Math.floor(Math.abs(date.diffNow('years').years))
}

const getLastUpdatedDataIndex = (data) => {
  let date = DateTime.fromISO("1970-01-01T00:00:00")
  let index = 0
  data?.forEach((el, idx) => {
    if (el.updatedDate && date < DateTime.fromISO(el.updatedDate)) {
      date = DateTime.fromISO(el.updatedDate)
      index = idx
    }
  })

  return index;
}

const getMISPhoneNumbers = (patient) => {
  const phoneNumbers = [];
  if (patient?.phonePrimary) phoneNumbers.push({ value: patient?.phonePrimary, source: 'MIS' })
  if (patient?.phoneEmergency) phoneNumbers.push({ value: patient?.phoneEmergency, source: 'MIS' })

  return phoneNumbers;
}

const getJMKPhoneNumbers = (jmkClient) => {
  const phoneNumbers = [];
  if (jmkClient?.phone) phoneNumbers.push({ value: jmkClient?.phone, source: 'JMK' })

  return phoneNumbers;
}

const getPhoneNumbers = (misPatient, jmkPatient) => {
  const misNumbers = getMISPhoneNumbers(misPatient);
  const jmkNumbers = getJMKPhoneNumbers(jmkPatient);
  return [...misNumbers, ...jmkNumbers]
}


const getMISAddresses = (patient) => {
  const addresses = [];

  patient?.addresses?.forEach((address) => {
    var formatedAddress = ""

    if (address?.apartmentSuite) {
      formatedAddress += address?.apartmentSuite + "-" + address?.line1

    } else {
      formatedAddress += address?.line1
    }

    if (address?.city && address?.province) {
      formatedAddress +=  ", "+address?.city + " " + address?.province
    }

    if(address?.postalCode){
      formatedAddress+= ", " +address?.postalCode
    }

    addresses.push({ value: formatedAddress, source: 'MIS' })

  });

  return addresses;
}

const getJMKAddresses = (jmkClient) => {
  const addresses = [];
  if (jmkClient?.address) addresses.push({ value: jmkClient?.address, source: 'JMK' })

  return addresses;
}

const getAddresses = (misPatient, jmkPatient) => {
  const misAddresses = getMISAddresses(misPatient);
  const jmkAddresses = getJMKAddresses(jmkPatient);
  return [...misAddresses, ...jmkAddresses]
}

const getGender = (gender) => {
  if (gender === 'M' || gender === 'm') return 'Male';
  if (gender === 'F' || gender === 'f') return 'Female';
  return 'Other'
}

const lineBreak = (para) => {
  if (para) return para.split(/\r?\n|\r|\n/g);
  return []
}

const getLegalName = (names) => {
  if (names && names.length > 0) {
    const legalNames = names.filter((name) => name.type === 'Legal Name')
    if (legalNames.length > 0) {
      return legalNames[0]
    }
  }

  return null;
}

const getPreferredName = (names) => {
  if (names && names.length > 0) {
    const preferredNames = names.filter((name) => name.isPreferred)
    if (preferredNames.length > 0) {
      return preferredNames[0]
    }
  }

  return null;
}

const removeWhitespaces = (data) => {
  if (data) {
    return data.split(" ").join("_")
  }

  return "";
}

export {
  getDateFormatted,
  getAge,
  getLastUpdatedDataIndex,
  getMISPhoneNumbers,
  getGender,
  lineBreak,
  getLegalName,
  getPreferredName,
  removeWhitespaces,
  getPhoneNumbers,
  getAddresses
}