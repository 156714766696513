import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Grid, Avatar, Box } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Logout from "@mui/icons-material/Logout"

import { useAuth } from "../../hooks/useAuth"
import { selectProfile } from "../../state/auth/selectors"
import { ROUTE_AUTH_CALLBACK } from "../../constants"
import useStyles from "./styles"

const ProfileBadge = ({ avatar }) => {
  const { logout } = useAuth()
  const profile = useSelector(selectProfile)
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    const redirectUri = `${process.env.REACT_APP_BASE_URL}${ROUTE_AUTH_CALLBACK}`
    logout(redirectUri)
  }

  const generateAvatarUrl = () => {
    if (avatar) {
      return avatar
    } else {
      const initials = `${profile?.firstName.charAt(
        0
      )}+${profile?.lastName.charAt(0)}`
      return `https://avatars.oxro.io/avatar.svg?name=${initials}&background=D449A8`
    }
  }

  return (
    <Grid container xs={12} sx={classes.gridContainer}>
      <Box sx={classes.box} onClick={handleOpenMenu} >
        <Avatar
          alt={`${profile?.firstName} ${profile?.lastName}`}
          src={generateAvatarUrl()}
          onClick={handleOpenMenu}
          sx={classes.avatar}
        />
        <Box sx={classes.name}>
          {profile?.firstName} {profile?.lastName}
        </Box>
      </Box>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export default ProfileBadge
