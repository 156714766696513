import { createSlice } from "@reduxjs/toolkit"

export const INITIAL_STATE = {
  mustimuhwData: null,
  jmkData: null
}

const patientSlice = createSlice({
  name: "patient",
  initialState: INITIAL_STATE,
  reducers: {
    updateData(state, { payload: { mustimuhwData, jmkData } }) {
      state.mustimuhwData = mustimuhwData
      state.jmkData = jmkData
    },
  },
})

export const { name, actions, reducer } = patientSlice
export const { updateData } = actions
export default reducer
