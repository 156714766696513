import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectAuthenticated = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated
);

export const selectProfile = createSelector(
  [selectAuth],
  (auth) => auth.profile
);

export const selectRoles = createSelector([selectAuth], (auth) => auth.roles);