import React, { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";

import useStyles from "./styles";
import CollapsibleCard from "../CollapsibleCard";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const PaginatedList = ({ clients, onDataSelected }) => {
  const classes = useStyles();

  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedResults = clients?.slice(startIndex, endIndex);

  const totalPages = Math.ceil(clients?.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSelection = (index) => {
    setSelectedIndex(index);
    onDataSelected(clients[index]);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(currentPage - halfMaxPagesToShow, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    const renderButton = (page) => (
      <Button
        key={page}
        onClick={() => handlePageChange(page)}
        variant={currentPage === page ? "contained" : "outlined"}
        sx={{
          backgroundColor: currentPage === page ? "#d7d7d7" : "#ffffff",
          color: "#696969",
          borderRadius: "6px",
          boxShadow: "none",
          height: "40px",
          width: "40px !important",
          margin: "10px 2px",
          border: "none",
          fontWeight: currentPage === page ? "700" : "500",
          fontSize: "16px",
          padding: "6px 0px",
          "&:hover": {
            backgroundColor: "#d7d7d7",
            boxShadow: "0px 0px 1px 1px rgb(20 46 110 / 10%)",
            border: "none",
          },
          "&:focus": {
            border: "none",
            boxShadow: "0px 0px 1px 1px rgb(20 46 110 / 10%)",
          },
        }}
      >
        {page}
      </Button>
    );

    return (
      <>
        {startPage > 1 && renderButton(1)}
        {startPage > 2 && <span style={{ margin: "10px 2px" }}>...</span>}
        {pageNumbers.map((page) => renderButton(page))}
        {endPage < totalPages - 1 && (
          <span style={{ margin: "10px 2px" }}>...</span>
        )}
        {endPage < totalPages && renderButton(totalPages)}
      </>
    );
  };

  return (
    <Grid xs={12} sx={classes.gridContainer}>
      <Grid item xs={12} sx={classes.sections}>
        {paginatedResults?.map((item, idx) => {
          const index = startIndex + idx;
          return (
            <CollapsibleCard
              data={item}
              index={index}
              isSelected={index === selectedIndex}
              setSelectedIndex={handleSelection}
            />
          );
        })}
      </Grid>
      <Grid item xs={12} sx={classes.navigation}>
        <IconButton
          onClick={handlePrevPage}
          sx={{
            borderRadius: "6px",
            height: "40px",
            margin: "10px 5px",
            "&:hover": {
              backgroundColor: "#d7d7d7",
              boxShadow: "0px 0px 1px 1px rgb(20 46 110 / 10%)",
            },
          }}
        >
          <ChevronLeft />
        </IconButton>

        {renderPageNumbers()}

        <IconButton
          onClick={handleNextPage}
          sx={{
            borderRadius: "6px",
            height: "40px",
            margin: "10px 5px",
            "&:hover": {
              backgroundColor: "#d7d7d7",
              boxShadow: "0px 0px 1px 1px rgb(20 46 110 / 10%)",
            },
          }}
        >
          <ChevronRight />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PaginatedList;
