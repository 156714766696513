import { useState } from "react"

import { Box, IconButton } from "@mui/material"
import Popover from '@mui/material/Popover';
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"

import Attachments from "../../Attachments"
import getStyles from './styles'

const AttachmentsButton = ({ encounterId }) => {
  const styles = getStyles();

  const [anchor, setAnchor] = useState(null)

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor)
  const id = open ? "simple-popper" : undefined

  return (
    <div>
      <IconButton onClick={handleClick} size='small'>
        <DescriptionOutlinedIcon
          sx={{
            cursor: "pointer",
            fontSize: "40",
            color: "#ff6565",
            fontWeight: "400",
            borderWeight: "1px",
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={styles.container}>
          <Attachments
            encounterId={encounterId}
          />
        </Box>
      </Popover>
    </div>
  )
}

export default AttachmentsButton
