import { css } from "@emotion/react";

const styles = () => {
  const searchBarContainer = css`
    align-items: flex-end;
    padding: 10px;
    justify-content: right;
    display: flex;
    flex-direction: row;
    width: 100%;
  `;

  const searchInput = css`
    padding: 10px 10px 10px 0;
    display: flex;
    align-items: center;
    font-family: Regular, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #676767;
  `;

  const searchButton = css`
    padding: 10px;
    color: #565656;
    font-family: SemiBold, sans-serif;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
  `;

  return {
    searchBarContainer,
    searchInput,
    searchButton,
  };
};

export default styles;
