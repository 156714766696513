import { useQuery } from "react-query"

import { patientDetailsApi } from "../../Api"

const useGetJMKClientById = ({ id }) => {
  const fetchClientById = async () => {
    try {
      if(!id){
        return []
      }

      const clients = await patientDetailsApi.get(
        `patient-details/api/v1/jmk/clients/${id}`
      )

      const data = await clients.json()

      return data
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return useQuery(["getJMKClientByID", id], fetchClientById, {
    refetchOnWindowFocus: false,
  })
}

export default useGetJMKClientById
