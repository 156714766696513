import React from "react";
import useStyles from "./styles";
import { Grid } from "@mui/material";
import { CheckRounded } from "@mui/icons-material";

const CustomCircleIcon = ({ isChecked }) => {
  const classes = useStyles();

  return (
    <Grid
      sx={{
        ...classes.circle,
        ...(isChecked && classes.checked),
      }}
    >
      {isChecked && <CheckRounded sx={classes.tik} />}
    </Grid>
  );
};

export default CustomCircleIcon;
