import React from "react";
import { Grid } from "@mui/material";

import useStyles from "./styles";
import SearchItem from "../SearchItem";
import InfiniteScroll from "react-infinite-scroll-component";
import useInitialPatientSearch from "../../hooks/services/useInitialPatientSearch";
import { getSearchTermTypes } from "./helpers";

const SearchResultsList = ({ searchTerm, onSelectedSearchResult }) => {
  const classes = useStyles();
  const searchParams = getSearchTermTypes(searchTerm);

  const { data: results } = useInitialPatientSearch(searchParams);
  const flattenedResults = results || [];

  return (
    <Grid xs={12} container sx={classes.gridContainer}>
      <Grid item xs={12} sx={classes.sections}>
        {flattenedResults?.length > 0 && (
          <Grid item xs={12} sx={classes.title}>
            <span>Search Results</span>
          </Grid>
        )}
        <Grid item xs={12} sx={classes.searchItemList}>
          <InfiniteScroll
            dataLength={flattenedResults?.length}
            next={() => {}}
            hasMore={false}
            refreshFunction={() => {}}
            loader={<p>Loading...</p>}
            scrollThreshold={0.4}
          >
            {flattenedResults
              ?.filter((result) => result.name !== "all")
              .map((item, index) => (
                <SearchItem data={item} key={index} onSelected={onSelectedSearchResult} />
              ))}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchResultsList;
