import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";
import SearchBar from "../SearchBar";
import ProfileBadge from "../ProfileBadge";
import { useSearch } from "../../pages/SearchContext";

const HeaderBar = ({ setSearchTerm }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { handleSearch } = useSearch();

  const onSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    handleSearch(searchTerm);
    navigate(`/search?q=${searchTerm}`);
  }

  return (
    <Grid xs={12} container sx={classes.gridContainer}>
      <Grid item xs={9} sx={classes.searchbar}>
        <SearchBar onSearch={onSearch} />
      </Grid>
      <Grid item xs={3} sx={classes.profile}>
        <ProfileBadge />
      </Grid>
    </Grid>
  );
};

export default HeaderBar;
