import { useQuery } from "react-query"

import { demogrphicsApi } from "../../Api"

const useGetPatientDemographics = (mustimuhwKey) => {
  const getPatientById = async () => {
    try {
      const patient = await demogrphicsApi.get(
        `demographics/api/v1/patients/${mustimuhwKey}`
      )

      const data = await patient.json()

      return data
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return useQuery(["getPatientById", mustimuhwKey], getPatientById, {
    refetchOnWindowFocus: false,
  })
}

export default useGetPatientDemographics
