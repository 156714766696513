import React, { useState } from "react"

import { Grid, Box } from "@mui/material"
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import InfiniteScroll from "react-infinite-scroll-component"

import AttachmentsButton from "./AttachmentsButton"
import LoadingIndicator from "../LoadingIndicator"
import useStyles from "./styles"
import useGetCaseNotes from "../../hooks/services/useGetCaseNotes"
import { getDateFormatted, lineBreak } from "../../util/helpers"

const CaseNoteCard = ({ patientId, isFullscreen, setFullscreen }) => {
  const classes = useStyles()
  const {
    data: caseNotes,
    hasNextPage,
    fetchNextPage,
  } = useGetCaseNotes(patientId)

  const flattenedCaseNotes = caseNotes?.pages.reduce((accum, curr) => {
    return [...accum, ...curr.caseNotes]
  }, [])

  const toggleExpand = () => {
    setFullscreen(!isFullscreen)
  }

  return (
    <Grid xs={12} sx={classes.gridContainer}>
      <Grid xs={12} sx={isFullscreen ? classes.cardFs : classes.card}>
        <Grid item xs={12} sx={classes.header}>
          <Box xs={11}>
            <span>Case Notes</span>
          </Box>
          <Box
            xs={1}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
            onClick={toggleExpand}
          >
            <Grid>
              <ArrowOutwardIcon
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right !important",
                  width: "15px",
                  height: "15px",
                  marginLeft: "13px",
                  "& svg": {
                    strokeWidth: 5,
                  },
                }}
              />
            </Grid>
            <Grid>
              <ArrowOutwardIcon
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  width: "15px",
                  height: "15px",
                  transform: "rotate(180deg)",
                  "& svg": {
                    strokeWidth: 5,
                  },
                }}
              />
            </Grid>
          </Box>
        </Grid>

        <Box id="scrollableContainer" sx={classes.content}>
          {!flattenedCaseNotes && <LoadingIndicator width={40} height={40} />}
          {flattenedCaseNotes && (
            <InfiniteScroll
              scrollableTarget="scrollableContainer"
              dataLength={flattenedCaseNotes?.length}
              next={() => fetchNextPage()}
              hasMore={hasNextPage}
              loader={<LoadingIndicator width={40} height={40} />}
            >
              {flattenedCaseNotes?.map((caseNote, index) => (
                <Grid sx={classes.set} key={index}>
                  <Grid item xs={12} spacing={1} sx={classes.row}>
                    <Grid sx={classes.line1}>
                      {getDateFormatted(caseNote.date)}
                    </Grid>
                    <Grid sx={classes.line2}>
                      {caseNote.program} - {caseNote.purpose}
                    </Grid>
                    <Grid sx={classes.line3}>
                      {caseNote.location}
                      {caseNote.location && "  |  "}
                      {caseNote.type}
                    </Grid>
                    <Grid sx={classes.syno}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontFamily: "Bold, sans-serif",
                          padding: "0px 0px 6px 0px",
                          color: "rgba(0, 0, 0, 0.75)",
                        }}
                      >
                        Synopsis:
                      </span>
                      <Grid sx={classes.synopsis}>{caseNote.synopsis}</Grid>
                      {caseNote.attachmentsCount > 0 && (
                        <Grid
                          item
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                            marginLeft: "auto",
                          }}
                        >
                          <AttachmentsButton
                            encounterId={caseNote.encounterId}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <hr style={{ marginTop: "5px", marginBottom: "12px" }}></hr>
                  <Grid item xs={12} sx={classes.row}>
                    {/* <span style={{ fontStyle: "italic", fontSize: "13px" }}>
                    {item.attachments}
                  </span> */}
                    {lineBreak(caseNote.notes).map((note, idx) => (
                      <Grid key={idx} sx={classes.para}>
                        {note}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </InfiniteScroll>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default CaseNoteCard
