import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `;

  const box = css`
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-right: 35px;
  `;

  const title = css`
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: SemiBold, sans-serif;
    font-size: 18px;
  `;

  const listwrapper = css`
    padding-top: 15px;
  `;

  const listitem = css`
    padding: 0px;
    color: #0d2b98;
    font-family: Regular, sans-serif;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      color: #000000;
    }
  `;

  return {
    gridContainer,
    title,
    listitem,
    listwrapper,
    box,
  };
};

export default styles;
