import { useQuery } from "react-query"

import { patientDetailsApi } from "../../Api"

const useGetAttachments = (encounterId) => {
  const fetchAttachments = async () => {
    try {
      const attachments = await patientDetailsApi.get(
        `patient-details/api/v1/attachments/${encounterId}`
      )

      const data = await attachments.json()
      return data?.attachments
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return useQuery(["getAttachments", encounterId], fetchAttachments, {
    refetchOnWindowFocus: false,
  })
}

export default useGetAttachments
