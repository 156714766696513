import { configureStore } from "@reduxjs/toolkit"

import { reducer as AuthReducer } from "./auth"
import { reducer as LastLocationReducer } from "./lastLocation"
import { reducer as PatientReducer } from "./patient"

export default configureStore({
  reducer: {
    auth: AuthReducer,
    lastLoc: LastLocationReducer,
    patient: PatientReducer,
  },
})
