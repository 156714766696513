import { Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"

import useStyles from "./styles"
import RecentSearch from "../../components/RecentSearch"
import SearchResultsList from "../../components/SearchResultsList"
import { useAuth } from '../../hooks/useAuth'
import { USER_ROLES } from '../../constants'

const PatientSearchResults = ({ searchTerm, setSearchTerm, setSelectedPatient }) => {
  const { isAuthorized } = useAuth()
  const classes = useStyles()
  const navigate = useNavigate()

  const onRecentSearchItemClicked = (item) => {
    setSearchTerm(item)
    navigate(`/search?q=${item}`)
  }

  const onSelectedSearchResult = (patient) => {
    setSelectedPatient(patient)
    if (isAuthorized(USER_ROLES.LEVEL_1)) {
      navigate(`/matching`)
    }
    else {
      navigate(`/profile`)
    }
  }

  return (
    <Grid container sx={classes.body}>
      <Grid item xs={3} sx={classes.recent}>
        <RecentSearch onItemClick={onRecentSearchItemClicked} />
      </Grid>
      <Grid item xs={9} sx={classes.searchresults}>
        <SearchResultsList
          searchTerm={searchTerm}
          onSelectedSearchResult={onSelectedSearchResult}
        />
      </Grid>
    </Grid>
  )
}

export default PatientSearchResults
