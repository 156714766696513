import { css } from "@emotion/react";

const styles = () => {
  const gridContainer = css`
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #528ed3;
  `;

  const box = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `;

  const avatar = css`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  `;

  const name = css`
    font-family: SemiBold, sans-serif;
    font-size: 14px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 14px;
    flex-direction: column;
  `;

  return {
    gridContainer,
    avatar,
    name,
    box,
  };
};

export default styles;
