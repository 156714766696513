import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import useStyles from "./styles";

const SearchBar = ({ onSearch }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
    setSearchTerm("");
  };

  return (
    <form
      style={{ width: "100%", display: "flex", justifyContent: "right" }}
      onSubmit={handleSearch}
    >
      <Grid xs={8} container sx={{ container: classes.searchBarContainer }}>
        <Grid item xs={10} sx={classes.searchInput}>
          <TextField
            fullWidth
            theme="light"
            placeholder="Search by Health Card number, status number, or legal name"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                background: "rgb(254, 254, 254)",
                borderRadius: "6px",
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(1, 85, 181)",
                  border: "1px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "8px 30px",
                fontSize: "15px",
                color: "#000000",
              },
            }}
          />
        </Grid>
        <Grid item xs={2} sx={classes.searchButton}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#ffffff",
              color: "#696969",
              textTransform: "none",
              boxShadow: "0px 0px 1px 1px rgb(20 46 110 / 10%)",
              borderRadius: "6px",
              fontWeight: "600",
              padding: "6px 30px",
              "&:hover": {
                backgroundColor: "#d9d9d9",
                boxShadow: "none",
              },
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchBar;
