import React, { useEffect } from "react"
import { Grid } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import useStyles from "./styles"
import RecentSearch from "../../components/RecentSearch"
import MustimuhwDataCard from "../../components/MustimuhwDataCard"
import DataMatchingList from "../../components/DataMatchingList"
import { selectMustimuhwData } from "../../state/patient/selector"
import useGetPatientDemographics from "../../hooks/services/useGetPatientDemographics"
import useGetDataMappings from "../../hooks/services/useGetDataMappings"
import useGetJMKClients from "../../hooks/services/useGetJMKClients"

const PatientMatching = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { mustimuhwPatient } = useSelector(selectMustimuhwData)
  const { data: patientDemographics } = useGetPatientDemographics(
    mustimuhwPatient?.mustimuhwKey
  )
  const { data: dataMapping, isLoading: isMustimuhwLoading } = useGetDataMappings({
    misId: mustimuhwPatient?.mustimuhwKey,
  })

  const { data: jmkClients, isLoading: isJmkLoading } = useGetJMKClients({
    firstName: mustimuhwPatient?.firstName,
    lastName: mustimuhwPatient?.lastName,
  })

  useEffect(() => {
    if (dataMapping && dataMapping.length != 0) {
      navigate('/profile', { state: dataMapping })
    }
  }, [dataMapping])

  useEffect(() => {
    if (jmkClients && jmkClients.length == 0) {
      navigate(`/profile`)
    }
  }, [jmkClients])

  const handleItemClick = (item) => {
    console.log("Item clicked:", item)
  }

  if (isMustimuhwLoading || isJmkLoading) {
    return <></>
  }

  return (
    <Grid container sx={classes.body}>
      <Grid item xs={3} sx={classes.recent}>
        <RecentSearch onItemClick={handleItemClick} />
      </Grid>
      <Grid item xs={9} sx={classes.details}>
        <Grid item xs={12} sx={classes.card}>
          <MustimuhwDataCard data={patientDemographics} />
        </Grid>
        <Grid item xs={12} sx={classes.list}>
          <DataMatchingList
            mustimuhwPatient={patientDemographics}
            jmkClients={jmkClients}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientMatching
