import { Grid } from "@mui/material"
import { Oval } from "react-loader-spinner"

const LoadingIndicator = ({ width, height, marginTop = 0 }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ width: "100%", height: "100%", marginTop }}
    >
      <Oval
        visible={true}
        height={height}
        width={width}
        color="#528ed3"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Grid>
  )
}

export default LoadingIndicator;